/* import div from '@material-ui/core/Grid'; */
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'

import './styles.scss'
function Header() {
  return (
    <header className='header-container'>
      <Navbar expand='lg'>
        <Container className='d-flex justify-content-md-center  left-side '>
          <Navbar.Brand href='/' className=''>
            <img
              src='/images/bago_white.png'
              width='auto'
              height='40'
              className='d-inline-block align-top'
              alt='Bagó'
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
        </Container>

        <Container className='r-menu'>
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto shadow-menu'>
              <Nav.Link href='/'>CONECTADOS</Nav.Link>
              <Nav.Link href='/prvademecum'>PR VADEMÉCUM</Nav.Link>
              <Nav.Link href='/rima'>RIMA</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
