import './styles.scss'
import './doctor.styles.scss'
const Doctor = () => {
  return (
    <section className='home-doctor container'>
      <div className='row'>
        <div className='col-12 col-md-4 order-md-last'>
          <img
            src='/images/medic.png'
            className='img-fluid doctor-icon'
            alt='bienvenido'
          />
        </div>
        <div className='col-12 col-md-8 d-flex align-items-center'>
          <div className='text-box d-flex align-items-center'>
            <p className='mobile'>
              Te damos la bienvenida a <br />
              <b>CONECTADOS | BAGÓ</b> <br />
              la aplicación móvil que te conectará con información científica,
              principios activos, capacitación on-line e invitaciones a webinars
              de salud exclusivos de Bagó.
            </p>
            <p className='desktop'>
              Te damos la bienvenida a <b>CONECTADOS | BAGÓ</b> la aplicación
              móvil que te conectará con información científica, principios
              activos, capacitación on-line e invitaciones a webinars de salud
              exclusivos de Bagó.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Doctor
