import Header from 'components/Header'
import StoreButtons from 'components/StoreButtons'
import './styles.scss'

function VademecumView() {
  return (
    <div className='vademecum'>
      <section
        className='resume'
        style={{
          backgroundImage: `url( ${
            process.env.PUBLIC_URL + '/images/background_x.png'
          })`,
          backgroundRepeat: 'repeat'
        }}
      >
        <Header />

        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-6 order-md-last '>
              <div className='description'>
                <div className='text'>
                  <h1> PR VADEMÉCUM </h1>
                  <h4>
                    CONTIENE INFORMACIÓN ACTUALIZADA DE MEDICAMENTOS Y DROGAS DE
                    PRESCRIPCIÓN MÉDICA COMERCIALIZADAS EN CHILE
                  </h4>

                  <StoreButtons
                    googlePlay='https://play.google.com/store/apps/details?id=com.xlsistemas.ba.vademecum_ch&hl=es_CL&gl=US'
                    appleStore='https://apps.apple.com/cl/app/pr-vadem%C3%A9cum-bag%C3%B3/id901488063'
                  />
                  {/* <p> DESCARGA LA APP EN TU DISPOSITIVO MÓVIL </p>

                  <div className='row store-links'>
                    <div className='col-md-4 col-6 p-0'>
                      <a href='https://play.google.com/store/apps/details?id=com.informed.prvademecumcl19&hl=es&gl=US'>
                        <img
                          src='/get_googleplay.svg'
                          alt='Google Playstore'
                          className='img-fluid'
                        />
                      </a>
                    </div>
                    <div className='col-md-4 col-6 p-0'>
                      <a href='https://apps.apple.com/cl/app/pr-vadem%C3%A9cum-bag%C3%B3/id901488063'>
                        <img
                          src='/get_ios.svg'
                          alt='Apple Store'
                          className='img-fluid'
                        />
                      </a>
                    </div>
                  </div> */}

                  {/*  <a href='https://play.google.com/store/apps/details?id=com.informed.prvademecumcl19&hl=es&gl=US'>
                    <img src='/get_googleplay.svg' alt='Google Playstore' />
                  </a>
                  <a href='https://apps.apple.com/cl/app/pr-vadem%C3%A9cum-bag%C3%B3/id901488063'>
                    <img src='/get_ios.svg' alt='Apple Store' />
                  </a> */}
                </div>
              </div>
            </div>

            <div className='col-12 col-md-6 text-left '>
              <div className='screen'>
                <img
                  src='/vademecum_screen.png'
                  className='img-fluid'
                  alt='vademecum screenshot'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='detail'>
        <p>
          <b> P.R.VADEMÉCUM </b> fue desarrollado a partir de fuentes
          confiables, como las empresas elaboradoras y la biblografía
          médico-farmacológica disponible en la actualidad.
        </p>
        <br />
        <br />
        <p>
          Si necesita la clave y usuario contacte a
          <a href='mailto:conectados@bago.cl'>
            <b> Conectados - Bagó </b>
          </a>
        </p>
      </section>
    </div>
  )
}

export default VademecumView
