import './styles.scss'
function StoreButtons({ googlePlay = '', appleStore = '', position = 'left' }) {
  return (
    <div className='store-buttons container'>
      <p> DESCARGA LA APP EN TU DISPOSITIVO MÓVIL </p>

      <div className='row store-links'>
        <div
          className={`${
            position === 'right' ? 'offset-md-4' : 'ups'
          } col-md-4 col-6 p-0`}
        >
          <a href={googlePlay} target='_blank' rel='noreferrer'>
            <img
              src='/get_googleplay.png'
              alt='Google Playstore'
              className='img-fluid'
            />
          </a>
        </div>
        <div className={`col-md-4 col-6 p-0`}>
          <a href={appleStore} target='_blank' rel='noreferrer'>
            <img src='/get_ios.png' alt='Apple Store' className='img-fluid' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default StoreButtons
