import Grid from '@material-ui/core/Grid'

import WelcomeHome from './welcome'
import Doctor from './doctor'
import Features from './features'
import Offline from './offline'
import Timeline from './timeline'

/* import './styles.scss' */
function HomeView() {
  return (
    <div className=''>
      <WelcomeHome />

      <Doctor />

      <Features />

      <Timeline />

      <Offline />
    </div>
  )
}

export default HomeView
