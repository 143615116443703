import Header from 'components/Header'
import StoreButtons from 'components/StoreButtons'
import './styles.scss'
function WelcomeHome() {
  return (
    <header className='home-header'>
      <div
        className='bg'
        style={{
          backgroundImage: `url( ${
            process.env.PUBLIC_URL + '/images/background_x.png'
          })`,
          backgroundRepeat: 'repeat',
          minHeight: '60vh',
          marginBottom: '5vh'
        }}
      >
        <Header />

        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-5 offset-md-3  text-left order-md-last welcome'>
              <img
                src='/conectados_icon.svg'
                className='conectados'
                alt='conectados'
              />
              <h1>
                Un mundo <br />
                por descubrir
              </h1>
              <h2>Exclusivo para ti.</h2>
              <div className='text-left '>
                <StoreButtons
                  googlePlay='https://play.google.com/store/apps/details?id=cl.conectadosbago.app&hl=es&gl=US'
                  appleStore='https://apps.apple.com/us/app/conectados-bag%C3%B3/id1618468237'
                />
              </div>
            </div>

            <div className='col-12 col-md-3'>
              <img
                src='/app_welcome.svg'
                className='img-fluid phone '
                alt='bienvenidos'
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default WelcomeHome
