import './styles.scss'

const Offline = () => {
  return (
    <section className=' home-offline'>
      <div className='row'>
        <div className='col-12 col-md-4  offset-md-4 '>
          <img
            src='/offline_phone.png'
            className='offline-phone'
            alt='Contenidos sin conexión'
          />
        </div>
        <div className='col-12 col-md-4 content d-flex align-items-center'>
          <div>
            <h4>
              Descarga tus contenidos favoritos y disfútalos <br />
              ¡cuando no tengas internet!
            </h4>
            <p>
              Sabemos que a veces no tienes acceso a una red de internet o tu
              señal es muy débil como para reproducir este tipo de contenidos.
              Por eso añadimos la sección "<b>descargas</b>", donde podrás
              almacenar todo tu contenido de interés.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Offline
