/* import div from '@material-ui/core/Grid'; */
import "./styles.scss";
import { div } from "@material-ui/core/Grid";
function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="terms">
              <img
                src="/bago_magenta.svg"
                className="img-fluid bago-magenta"
                alt="Bagó"
              />
              <ul>
                <li>
                  <a href="/politicas">Términos y condiciones</a>
                </li>
                <li>
                  <a href="/politicas">Políticas de privacidad</a>
                </li>
                <li>
                  <a href="/prvademecum">PR Vademecum</a>
                </li>
                <li>
                  <a href="/">Conectados</a>
                </li>
                <li>
                  <a href="/rima">RIMA</a>
                </li>

                <li>
                  <a
                    href="https://bagochile.cl"
                    target="_blank"
                    className="link-bago"
                    rel="noreferrer"
                  >
                    Ir a bagochile.cl
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="container follow">
              <div className="row justify-content-center">
                <div className="col-12 col-md-4">
                  <p>SÍGUENOS</p>
                </div>
                <div className="col-4 col-md-4">
                  <a
                    href="https://instagram.com/bagochile?igshid=YmMyMTA2M2Y="
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/images/instagram.svg"
                      className="img-fluid"
                      alt="instagram"
                    />
                  </a>
                </div>
                <div className="col-4 col-md-4">
                  <a
                    href="https://www.facebook.com/bagochile"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/images/facebook.svg"
                      className="img-fluid"
                      alt="facebook"
                    />
                  </a>
                </div>
                {/*  <div className='col-4 col-md-3'>
                  {' '}
                  <a href='/#' target='_blank' rel='noreferrer'>
                    <img
                      src='/images/twitter.svg'
                      className='img-fluid'
                      alt='twitter'
                    />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 ">
            <div className="copy">
              <p>Todos los derechos reservados Bagó Chile &reg;</p>
              <a
                className="boreal"
                href="https://www.agenciaboreal.cl/"
                target="_blank"
                alt="agencia boreal"
                rel="noreferrer"
              >
                powered by{" "}
                <img
                  className="boreal-icon"
                  src="/boreal_icon.svg"
                  alt="BOREAL"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
