import Grid from "@material-ui/core/Grid";
import Header from "components/Header";
import "./styles.scss";

function PoliticsView() {
  return (
    <div className="politics">
      <header className="container text-center">
        <h1>Políticas de Privacidad</h1>
        <h3>Conectados Bagó Chile</h3>
      </header>
      <section className="container">
        <div className="row">
          <div className="col-12 offset-md-2 col-md-8">
            <p>
              Queremos darle la bienvenida a nuestro{" "}
              <b>Programa Conectados-Bagó</b>, que tiene por finalidad
              colaborar, apoyar y desarrollar actividades en salud, a través de
              contactos virtuales, que nos permiten contribuir con Educación
              Médica Continua a los profesionales del área de la Salud, que por
              medio de nuestra Aplicación móvil <b>Conectados-Bagó</b>,
              conectará con bases de información científica, información
              actualizada de medicamentos, descripciones farmacológicas,
              indicaciones, principios activos, patología y laboratorios que
              comercializan en Chile, videos de capacitaciones on-line en temas
              específicos dictados por líderes en sus áreas científicas,
              webinars en salud, exclusivos de Laboratorio Bagó.
            </p>

            <p>
              Para poder utilizar la <b>App Conectados-Bagó</b> será necesario
              ingresar algunos datos personales como su nombre, dirección, RUT,
              email y para eso requerimos de su consentimiento en conformidad
              con la ley No.19.628 sobre Protección de la Vida Privada, los
              datos suministrados por usted, quedarán incorporados en las bases
              de datos administradas por Bagó o por terceros designados por
              nosotros.
            </p>

            <p>
              El tratamiento de esta información es para la finalidad de
              entregar un servicio y/o información de su interés que pueden
              tener contenidos con o sin fines promocionales y/o estadísticos.
            </p>
            <p>
              Los datos personales serán tratados con el grado de protección
              adecuado, tomándose las medidas de seguridad necesarias para
              evitar su alteración, pérdida, tratamiento o acceso no autorizado
              por parte de terceros que los puedan utilizar para finalidades
              distintas o incompatibles para las que han sido solicitados al
              titular. Así también, se puede solicitar de manera voluntaria y automática
              la eliminación de sus datos y cuenta de acceso a la app Conectados-Bagó en
              la pestaña de <b>"Mi Perfil"</b> opción <b>"Eliminar mi cuenta"</b>, de esta misma plataforma, removiendo completamente los
              datos personales vinculados a la aplicación.
            </p>

            <p>
              Autorizo expresamente a Laboratorio Bago S.A. para que utilicen
              mis datos personales para participar en el Programa{" "}
              <b>Conectados-Bagó</b>.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PoliticsView;
