import React from "react";

import { Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";

import HomeView from "./views/Home";
import VademecumView from "./views/Vademecum";
import RimaView from "./views/Rima";
import PoliticsView from "./views/Politics";

function App() {
  return (
    <div className="">
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="/prvademecum" element={<VademecumView />} />
        <Route path="/rima" element={<RimaView />} />
        <Route path="/politicas" element={<PoliticsView />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
