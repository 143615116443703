import StoreButtons from 'components/StoreButtons'
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import './styles.scss'
import './timeline.styles.scss'
const Timeline = () => {
  return (
    <section className='home-steps'>
      <h2>Sigue el paso a paso para unirte a </h2>
      <img
        src='/images/conectados_icon_color.svg'
        className='img-fluid conectados-color'
        alt='Sigue el paso a paso para unirte a CONECTADOS'
      />{' '}
      {/* */}
      <VerticalTimeline layout='2-columns'>
        <VerticalTimelineElement
          className='vertical-timeline-element--work  '
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          style={{ textAlign: 'right ' }}
        >
          <div>
            <StoreButtons
              position='right'
              googlePlay='https://play.google.com/store/apps/details?id=cl.conectadosbago.app&hl=es&gl=US'
              appleStore='https://apps.apple.com/us/app/conectados-bag%C3%B3/id1618468237'
            />
          </div>
          <h3 className='vertical-timeline-element-title'>
            Descarga la app en tu smartphone
          </h3>

          <p>
            Dirígete a la tienda de aplicaciones correspondiente de tu
            smartphone para descargar Conectados - Bagó
          </p>

          {/* <p className='link'>PRÓXIMAMENTE DISPONIBLE</p> */}
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className='vertical-timeline-element--work'
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          style={{ textAlign: 'left' }}
        >
          <h3 className='vertical-timeline-element-title'>
            Lee y acepta los términos y condiciones
          </h3>

          <p>
            Queremos que te sientas segur@ navegando por los contenidos
            disponibles en esta plataforma
          </p>
          <a
            href='https://vod-input-bago.s3.us-west-2.amazonaws.com/politics_ce1bce177d.pdf?updated_at=2022-04-20T23:31:30.989Z'
            target='_blank'
            className='btn btn-primary'
            rel='noreferrer'
          >
            Leer términos y condiciones
          </a>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className='vertical-timeline-element--work'
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          style={{ textAlign: 'right' }}
        >
          <h3 className='vertical-timeline-element-title'>
            Recibirás un e-mail con la confirmación
          </h3>

          <p>
            Cuando validemos tus datos, te avisaremos vía e-mail para que puedas
            comenzar a usar la app Conectados - Bagó
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </section>
  )
}

export default Timeline
