import Grid from '@material-ui/core/Grid'
import Header from 'components/Header'
import './styles.scss'

function RimaView() {
  return (
    <div className='rima'>
      <header className='rima-header'>
        <Header />

        <div className='row'>
          <div className='col-12 col-md-7 order-md-last'>
            <div className='description'>
              <h1>RIMA</h1>
              <h4>
                PLATAFORMA LÍDER DE INFORMACIÓN CIENTÍFICA EN IBEROAMÉRICA,
                NUESTRO CAMPUS VIRTUAL ES SU LUGAR DE ENCUENTRO CON LA MEJOR
                INFORMACIÓN CIENTÍFICA Y EL ACERVO BIBLIOGRÁFICO MAS COMPLETO.
              </h4>

              <a
                className='btn btn-warning'
                href='https://www.rima.org/Account/Login?ReturnUrl=%2F&code=397104-8101-1241%0D'
                target='_blank'
                rel='noreferrer'
              >
                IR A RIMA
              </a>
            </div>
          </div>
          <div className='text-center col-12 col-md-5 '>
            <img
              src='/rima_bg.png'
              className='img-fluid rima-img '
              alt='bagó'
            />
          </div>
        </div>
      </header>
      <section className='rima-space'>
        <p>
          Si necesita la clave y usuario contacte a
          <a href='mailto:conectados@bago.cl'>
            <b> Conectados - Bagó </b>
          </a>
        </p>
      </section>
    </div>
  )
}

export default RimaView
