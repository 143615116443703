import './styles.scss'
const Features = () => {
  return (
    <section className='container home-features'>
      <div className='row'>
        <div className='col-12 col-md-4'>
          <div className='feature-box'>
            <div className='icon'>
              <img src='/video_icon.svg' alt='Video' />
            </div>
            <h3>Video</h3>
            <p>
              Reproduce sin limitaciones y en calidad full HD, una gran variedad
              de videos exclusivos de bagó. Conversatorios con expertos de cada
              área de la medicina, documentales, etc. Todo a un click de
              distancia
            </p>
          </div>
        </div>
        <div className='col-12 col-md-4'>
          <div className='feature-box'>
            <div className='icon'>
              <img src='/podcast_icon.svg' alt='Video' />
            </div>
            <h3>Podcast</h3>
            <p>
              Escucha en formato de audio, interesantes conversaciones del mundo
              de la medicina y ciencia. Todo bajo la supervición de Laboratorios
              Bagó
            </p>
          </div>
        </div>
        <div className='col-12 col-md-4'>
          <div className='feature-box'>
            <div className='icon'>
              <img src='/books_icon.svg' alt='Video' />
            </div>
            <h3>Libros</h3>
            <p>
              Accede a una tremenda biblioteca de libros escenciales para las
              diferentes áreas de la medicina. Léelos directamente en la
              plataforma de manera fácil y gratuita.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
